<template>
  <div class="user-side-status">
    <div class="ic-ell">
      <div class="ic-ell__icon">
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
      </div>
      <span class="ic-ell__text">
        Количество заказов: <span>{{ ordersCount }}</span>
      </span>
    </div>
    <div class="ic-ell">
      <div class="ic-ell__icon">
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
      </div>
      <span class="ic-ell__text">
        Общий доход: <span>{{ income }} руб.</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ordersCount: {
      type: [String, Number],
      required: true,
    },
    income: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>


// user-side-status
.user-side-status {
  padding: 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .ic-ell {
    padding: 8px 14px;
    .ic-ell__text {
      font-size: 15px;
    }
  }
  @include respond-to('sm') {
    .ic-ell {
      padding: 10px 16px;
      .ic-ell__text {
        font-size: 17px;
      }
    }
  }
}


</style>
