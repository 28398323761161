<template>
  <SideWrapper>
    <SideAvatar :editRoute="{ name: $routeName.ASSISTANCE_PROVIDE_COMPANY_EDIT_PROFILE }"></SideAvatar>
    <SideOrdersAndIncome :ordersCount="14" :income="45300"></SideOrdersAndIncome>

    <SideCollapse>
      <SideCompanyAttrs></SideCompanyAttrs>
    </SideCollapse>
    <div class="side-panel__switch">
      <div class="side-panel__label">Оказываем помощь</div>
      <v-switch
        @change="switchHandler"
        :input-value="isWorking"
      />
    </div>
    <router-link
      v-if="$routeName.ASSISTANCE_PROVIDE_COMPANY_EMPLOYEES !== $route.name"
      :to="{ name: $routeName.ASSISTANCE_PROVIDE_COMPANY_EMPLOYEES }"
      class="btn btn--prime">
      <span>Сотрудники</span>
      <svg viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'btn-next__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.797 16.674a.978.978 0 01-.739.326C.471 17 0 16.502 0 15.87c0-.317.124-.604.329-.825L6.556 8.49.329 1.955a1.224 1.224 0 01-.33-.824C0 .498.472 0 1.06 0c.293 0 .542.115.738.326l6.92 7.283c.25.249.365.556.374.891 0 .335-.125.623-.374.882l-6.92 7.292z"/></svg>
    </router-link>
    <router-link
      v-else
      :to="{ name: $routeName.ASSISTANCE_PROVIDE_COMPANY_SEARCH_EMPLOYEE }"
      class="btn btn--prime"
    >
      <span>Поиск новых сотрудников</span>
      <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'btn-search__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M25.076 20.489l-4.279-4.28c.778-1.556 1.297-3.371 1.297-5.187A11.013 11.013 0 0011.07 0 11.013 11.013 0 00.05 11.022 11.013 11.013 0 0011.07 22.045c1.816 0 3.631-.519 5.187-1.297l4.28 4.28c1.296 1.296 3.241 1.296 4.538 0a3.38 3.38 0 000-4.54zM11.071 19.45a8.412 8.412 0 01-8.429-8.429 8.412 8.412 0 018.43-8.428 8.412 8.412 0 018.428 8.428 8.412 8.412 0 01-8.429 8.43z"/></svg>
    </router-link>
  </SideWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SideAvatar from '@/component/Module/SidePanel/SideAvatar.vue';
import SideCollapse from '@/component/Module/SidePanel/SideCollapse.vue';
import SideOrdersAndIncome from '@/component/Module/SidePanel/SideOrdersAndIncome.vue';
import SideCompanyAttrs from '@/component/Module/SidePanel/SideCompanyAttrs.vue';
import SideWrapper from '@/component/Module/SidePanel/SideWrapper.vue';

export default {
  name: 'SidePanel',
  components: {
    SideAvatar,
    SideCollapse,
    SideOrdersAndIncome,
    SideCompanyAttrs,
    SideWrapper,
  },
  data() {
    return {
      switchBtn: true,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isWorking() {
      return this.user.working;
    }
  },
  methods: {
    ...mapActions('user', ['updateUserProfileData']),
    async switchHandler(value) {
      await this.updateUserProfileData({ user: { working: value }});
    }
  }
};
</script>

<style lang="scss" scoped>

.side-panel__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 14px;
  border-bottom: 1px solid rgba(#000000, 0.2);
  .side-panel__label {
    font-size: 17px;
  }
}
@include respond-to('sm') {
  .side-panel__switch {
    height: 60px;
    padding: 0 16px;
    .side-panel__label {
      font-size: 19px;
    }
  }
}

.btn {
  position: relative;
  margin: 20px 14px 0;
}

.btn-search__svg {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
  margin-left: 20px;
}

.btn-next__svg {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  fill: #FFFFFF;
}

</style>
