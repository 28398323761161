<template>
  <div class="user-side-info">
    <div class="user-side-info__list">
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Наименование компании:</div>
        <div class="user-side-info__ell">{{ innerCompany.name }}</div>
      </div>
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Организационно-правовая форма:</div>
        <div class="user-side-info__ell">{{ innerCompany.legal_form.name }}</div>
      </div>
      <div class="user-side-info__bl">
        <div class="user-side-info__ell">Количество сотрудников:</div>
        <div class="user-side-info__ell">{{ innerCompany.staff_num }}</div>
      </div>
      <!-- <div class="user-side-info__bl">
        <div class="user-side-info__ell">{{ company }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    company: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    innerCompany() {
      return this.company ? this.company : this.user.company;
    },
  },
};
</script>





<style lang="scss" scoped>


// user-side-info
.user-side-info {

  .user-side-info__list {
    margin-bottom: 20px;
    .user-side-info__bl {
      display: flex;
      margin-bottom: 12px;
      .user-side-info__ell {
        color: rgba(#020202, 0.9);
        font-size: 13px;
        &:first-child {
          margin-right: 6px;
        }
      }
    }
  }

  @include respond-to('sm') {
    .user-side-info__list {
      margin-bottom: 28px;
      .user-side-info__bl {
        .user-side-info__ell {
          font-size: 15px;
        }
      }
    }
  }
}


</style>
